<template>
  <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
    <div class="select-container">
      <div
        class="select-input d-flex overflow-hidden"
        style="flex-wrap: wrap"
        :class="{ grey: backgroundColor === 'grey' }"
        @click="isOpen = !isOpen"
        role="button"
        tabindex="0"
      >
        <div v-if="selected" class="selection">
          <slot name="selected-label" :extended="selected.extended">
            {{ placeholderGenerated || selected.label }}
          </slot>
        </div>
        <div v-else class="selection-empty">
          {{ placeholderGenerated || placeholder }}
        </div>
        <span class="icon">
          <CarrotDownWide />
        </span>
      </div>
      <div class="select-dropdown-container">
        <InstaSearch
          v-if="isOpen"
          class="search"
          :class="{ active: search }"
          :data="options"
          placeholder="Suchen"
          @filtered-data="handleFilteredData"
          :filter-options="filterOptions"
        />
        <div v-if="isOpen" class="select-dropdown">
          <div v-if="!multiSelect" class="options-list">
            <div
              v-for="item of filteredData"
              @click="selectSingleItem(item)"
              role="button"
              tabindex="0"
              :key="item.value"
              class="options-item"
            >
              <slot name="list-label" :extended="item.extended">
                {{ item.label }}
              </slot>
            </div>
          </div>
          <div v-else class="options-list">
            <div
              v-for="item of filteredData"
              :key="item.value"
              class="options-item multi"
            >
              <CheckboxMultiElement
                :value="item.value"
                :model-value="selectedArray"
                @input="selectedArray = $event"
              />
              <span>
                <slot :extended="item.extended">
                  {{ item.label }}
                </slot>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OutsideClickContainer>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api';
import CarrotDownWide from '@/components/Svgs/Carrots/CarrotDownWide.vue';
import CheckboxMultiElement from '@/components/FormControl/CheckboxMultiElement.vue';
import useSingleSelect from '@/components/FormControl/SelectElement/composables/useSingleSelect';
import useMultiSelect from '@/components/FormControl/SelectElement/composables/useMultiSelect';
import InstaSearch from '@/components/Search/InstaSearch.vue';
import OutsideClickContainer from '@/components/OutsideClickContainer/OutsideClickContainer.vue';

export default {
  setup(props, { emit }) {
    const isOpen = ref(false);
    const filteredData = ref(null);

    const [selected, selectSingleItem] = useSingleSelect(isOpen, emit);
    let [selectedArray] = useMultiSelect(isOpen, emit);

    onMounted(() => {
      if (!Array.isArray(props.value)) {
        selected.value = {
          value: props.value.value,
          label: props.value ? props.value.label : props.placeholder, // capitalize(props.value ? props.value : props.placeholder),
        };
      } else {
        selectedArray.value = props.value;
      }
    });

    watch(isOpen, (newIsOpen, oldIsOpen) => {
      if (!newIsOpen && oldIsOpen) {
        emit('dropdown-closed', isOpen.value);
      }
    });

    const handleFilteredData = value => {
      filteredData.value = value;
    };

    return {
      isOpen,
      selectSingleItem,
      selected,
      selectedArray,
      handleFilteredData,
      filteredData,
    };
  },
  computed: {
    placeholderGenerated() {
      let str = '';

      for (let i = 0; i < this.selectedArray.length; ++i) {
        const item = this.options.find(o => o.value == this.selectedArray[i]);

        if (!item) {
          continue;
        }

        str += item.label;

        if (i != this.selectedArray.length - 1) {
          str += ', ';
        }
      }

      return str;
    },
  },
  components: {
    InstaSearch,
    CheckboxMultiElement,
    CarrotDownWide,
    OutsideClickContainer,
  },
  props: {
    value: {
      type: [Number, String, Array, Object],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  width: 100%;
}
.select-input {
  width: 100%;
  height: 44px;
  border: $hty-border;
  border-radius: $hty-radius;
  outline: none;
  padding-left: 0.9rem;
  padding-right: 2.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.grey {
    background-color: $hty-grey;
    border: 1px solid $hty-grey;
  }

  &.touched {
    border-color: $hty-red2;
  }
  &.active {
    border-color: $hty-red2;
  }
  &.passed {
    border-color: $hty-grey-border;
  }

  > .icon {
    position: absolute;
    right: 16px;
    top: 10px;
  }
}
.select-dropdown-container {
  width: 100%;
  border-radius: $hty-radius;
  background: $hty-white;
  box-shadow: $hty-shadow-dropdown;
  position: absolute;
  top: 50px;
  z-index: $z-index-dropdowns;
}
.select-dropdown {
  max-height: 200px;
  overflow-y: auto;
  outline: none;
}
.options-list {
}
.options-item {
  padding: 0.5rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  cursor: pointer;
  padding-left: 0.9rem;
  outline: none;
  display: flex;

  > :first-child {
    margin-right: 1.5rem;
  }

  &.multi {
    cursor: initial;
  }
}
.selection {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.selection-empty {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.search {
  display: none;
  height: 44px;

  &.active {
    display: block;
  }

  ::v-deep .input {
    border: none;
    background-color: $hty-white;
    border-bottom: 1px solid $hty-orange;
    border-radius: initial;
  }
}
</style>
