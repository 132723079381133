<template>
  <ValidationProvider
    :rules="validationRules"
    :name="validationName"
    v-slot="{ errors, failed, classes, pristine }"
    :tag="validationProviderTag"
  >
    <textarea
      class="text-area"
      :style="'height: ' + height"
      :class="{
        disabled,
        ...classes,
        active: pristine && failed,
      }"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
      ref="input"
      @keyup="$emit('keyup', $event.key)"
    />
    <div
      v-if="failed"
      class="error"
      :class="classes"
      :data-testid="`${validationName.toLowerCase()}-error`"
    >
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: String,
      default: '',
    },
    validationName: {
      type: String,
      default: '',
    },
    validationProviderTag: {
      type: String,
      default: 'span',
    },
    height: {
      type: String,
      default: '60px',
    },
  },
  mounted() {
    if (this.focus) this.focusInput('input');
  },
  data() {
    return {
      //
    };
  },
  methods: {
    focusInput(inputRef) {
      this.$refs[inputRef].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  width: 100%;
  // height: 60px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  color: $hty-dark-grey;

  @include hty-placeholder-color;

  &.touched {
    border-color: $hty-red2;
  }
  &.active {
    border-color: $hty-red2;
  }
  &.passed {
    border-color: $hty-grey-border;
  }
}

.error {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.2;
  color: $hty-red2;
}

.disabled {
  cursor: not-allowed;
  // color: $htl-grey3;
}
</style>
