<template>
  <div
    v-if="successStatus"
    :class="['success-notification', { active: successStatus }]"
  >
    <slot />
  </div>
</template>

<script>
import store from '@/store';
// add later support for not successful action in store and in here
export default {
  name: 'SuccessNotification',

  methods: {
    closeModal() {
      store.commit('setShowSuccessfulActionModal', false);
    },
  },
  computed: {
    successStatus() {
      return store.getters.showSuccessfulActionModal;
    },
  },
  watch: {
    successStatus() {
      if (this.successStatus) {
        setTimeout(() => {
          this.closeModal();
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.success-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  padding: 2rem 3rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  opacity: 0;
  font-size: 20px;
  background: #e58413;
  border-radius: 10px;
  color: white;
  transition: 0.1s height, opacity;

  &.active {
    height: auto;
    opacity: 1;
  }
}
</style>
