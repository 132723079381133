<template>
  <div class="settings-products-add">
    <div class="header row">
      <div class="title col-12 col-md-6">
        {{ $t('serviceProvider.settingsProduct.products') }}
        ->
        {{ $t('serviceProvider.settingsProduct.createProduct') }}
      </div>
    </div>

    <div class="content row">
      <div class="left col-12 col-md-6 mb-5 mb-md-0">
        <keep-alive>
          <Gallery @images="setImages" :edit="edit" />
        </keep-alive>
      </div>
      <div class="right col-12 col-md-6">
        <Form @updateForm="updateForm" :edit="edit" />
      </div>
    </div>

    <div class="row" style="margin-top: 30px">
      <div class="col-12">
        <DeliveryInfo v-model="product.deliveryInfo" />
      </div>
    </div>

    <div>
      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'editProduct'"
        :response="$store.getters.errorResponse.data"
        class="mt-3"
      ></ResponseAlert>
    </div>

    <div class="actions">
      <ButtonElement
        :orange-secondary="true"
        @click="$router.push({ name: 'SettingsProducts' })"
      >
        {{ $t('serviceProvider.settingsProduct.cancel') }}
      </ButtonElement>
      <template v-if="!edit">
        <ButtonElement
          :orange="true"
          :loading="$store.getters.loading"
          @click="addProduct"
        >
          {{ $t('serviceProvider.settingsProduct.save') }}
        </ButtonElement>
      </template>
      <template v-else>
        <ButtonElement :orange="true" @click="updateProduct">
          {{ $t('serviceProvider.settingsProduct.save') }}
        </ButtonElement>
      </template>
    </div>

    <SuccessNotification>
      Deine Dienstleistung wurde erfolgreich erstellt!
    </SuccessNotification>
  </div>
</template>

<script>
import Gallery from '@/components/Products/ProductsGallery';
import Form from '@/components/Products/ProductsForm';
import DeliveryInfo from '@/components/Products/ProductsDeliveryInformation';
import SuccessNotification from '@/components/Staff/SuccessNotification';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  components: {
    Gallery,
    Form,
    DeliveryInfo,
    SuccessNotification,
    ResponseAlert,
  },

  props: {
    type: [String, Number],
  },

  data() {
    return {
      product: {
        form: {},
        images: [],
        deliveryInfo: {
          delivery: {
            enabled: true,
            minimum_order: 1,
            delivery_days: 1,
            costs: [
              {
                min: 1,
                max: 5,
                cost: 10,
              },
            ],
          },
          pickup: {
            enabled: true,
            minimum_order: 1,
            packing_days: 1,
          },
        },
      },
      edit: false,
    };
  },

  created() {
    this.$store.commit('setErrorResponse', {});

    if (this.$route.params.product) {
      this.populateData(this.$route.params.product);
    }
  },

  methods: {
    updateForm(data) {
      this.product.form = data;
    },
    setImages(images) {
      this.product.images.push(images);
    },
    populateData(data) {
      console.log(data);
      this.edit = data;
      this.product.deliveryInfo = JSON.parse(data.delivery);
    },
    addProduct() {
      this.$store.commit('setErrorResponse', {});

      this.$store.dispatch('addProduct', this.getFormData()).then(() => {
        this.$router.push({ name: 'SettingsProducts' });
      });
    },
    updateProduct() {
      this.$store.commit('setErrorResponse', {});
      // this.tabs.service.form.images = this.tabs.service.images;

      this.$store.dispatch('updateProduct', {
        id: this.edit.id,
        data: this.getFormData(),
      });
      this.$router.push({ name: 'SettingsProducts' });
    },
    getFormData() {
      const data = new FormData();

      Object.keys(this.product.form).forEach(key => {
        if (this.product.form[key] || this.product.form[key] === 0) {
          data.append(key, this.product.form[key]);
        }
      });

      this.product.images.forEach(img => {
        data.append('images[]', img);
      });

      data.append('delivery', JSON.stringify(this.product.deliveryInfo));
      data.append('in_store', 1);

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .title {
    font-weight: bold;
    font-size: 24px;
    color: #e58413;
    margin-bottom: 0;
    padding-right: 100px;
  }
}

.tabs {
  .tab {
    font-weight: 500;
    font-size: 20px;
    color: #999999;
    padding-right: 30px;
    cursor: pointer;

    &.active {
      font-weight: bold;
      font-size: 24px;
      color: #e58413;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button {
    width: 33%;
    justify-content: center;
  }
}
</style>
