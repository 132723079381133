<template>
  <div class="products-delivery-information">
    <div class="header" @click="toggle">
      {{ $t('serviceProvider.settingsProduct.deliveryInformation') }}
      <ArrowIcon
        :class="['arrow-icon', { expanded: expanded }]"
        width="14"
        height="14"
      />
    </div>

    <div :class="['content-wrap', { expanded: expanded }]">
      <div class="row">
        <!-- Delivery -->
        <div class="col">
          <div class="title">
            {{ 'Delivery' }}
          </div>

          <CheckboxElement
            class="mb-3"
            v-model="value.delivery.enabled"
            text="Delivery available"
          />

          <div :class="{ 'disabled-content': !value.delivery.enabled }">
            <!-- Min order -->
            <div class="mb-3">
              <label class="label">
                {{ 'Minimum order' }}
              </label>
              <InputElement
                v-model="value.delivery.minimum_order"
                type="number"
              />
            </div>

            <!-- Delivery time -->
            <div class="mb-3">
              <label class="label">
                {{ 'Delivery time (in days)' }}
              </label>
              <InputElement v-model="value.delivery.delivery_days" />
            </div>

            <!-- Delivery costs -->
            <div class="mb-3">
              <label class="label">
                {{ 'Delivery costs' }}
              </label>

              <div class="row mb-1">
                <div class="col">Min</div>
                <div class="col">Max</div>
                <div class="col">Cost</div>
                <div class="col"></div>
              </div>

              <div
                v-for="(cost, index) in value.delivery.costs"
                class="row mb-2"
                :key="index"
              >
                <template v-if="cost">
                  <div class="col">
                    <InputElement v-model="cost.min" type="number" />
                  </div>
                  <div class="col">
                    <InputElement v-model="cost.max" type="number" />
                  </div>
                  <div class="col">
                    <InputElement v-model="cost.cost" type="number" />
                  </div>
                  <div class="col">
                    <ButtonElement orange @click="deleteDeliveryCost(index)">
                      -
                    </ButtonElement>
                  </div>
                </template>
              </div>

              <div class="row">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col">
                  <ButtonElement orange @click="addDeliveryCost">
                    +
                  </ButtonElement>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Pickup -->
        <div class="col">
          <div class="title">
            {{ 'Pickup' }}
          </div>

          <CheckboxElement
            class="mb-3"
            v-model="value.pickup.enabled"
            text="Pickup available"
          />

          <div :class="{ 'disabled-content': !value.pickup.enabled }">
            <!-- Min order -->
            <div class="mb-3">
              <label class="label">
                {{ 'Minimum order' }}
              </label>
              <InputElement
                v-model="value.pickup.minimum_order"
                type="number"
              />
            </div>

            <!-- Delivery time -->
            <div class="mb-3">
              <label class="label">
                {{ 'Packing time (in days)' }}
              </label>
              <InputElement v-model="value.pickup.packing_days" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/Svgs/Arrow';

export default {
  components: {
    ArrowIcon,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },

    deleteDeliveryCost(index) {
      delete this.value.delivery.costs[index];
      this.$forceUpdate();
    },

    addDeliveryCost() {
      this.value.delivery.costs.push({
        min: null,
        max: null,
        cost: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.products-delivery-information {
  border-top: 1px solid #999999;
}

.disabled-content {
  pointer-events: none;
  opacity: 0.4;
}

.header {
  padding: 20px 0 20px 0;
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  color: #e58413;

  .arrow-icon {
    transform: rotate(-90deg);
    transition: transform 0.2s;
    margin-left: 10px;
    &.expanded {
      transform: rotate(0deg);
    }
  }
}

.content-wrap {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;

  &.expanded {
    height: auto;
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  color: #e58413;
  margin-bottom: 10px;
  border-bottom: 1px solid #e58413;
}
</style>
