<template>
  <div class="products-form">
    <div class="input-section">
      <label>
        {{ $t('serviceProvider.settingsProduct.nameOfTheProduct') }}
      </label>
      <InputElement
        v-model="name"
        validation-rules="required"
        validation-name="text"
        :placeholder="$t('serviceProvider.settingsProduct.nameOfTheProduct')"
        @blur="updateForm"
      />
    </div>

    <div class="input-section">
      <label>{{ $t('serviceProvider.settingsProduct.category') }}</label>
      <SelectElement
        v-model="category.selected"
        :options="categories"
        validation-rules="required"
        validation-name="text"
        @input="updateCategorySelected"
      />
    </div>

    <div class="double-section">
      <div class="input-section" style="margin-right: 1rem">
        <label>{{ $t('serviceProvider.settingsProduct.price') }} (EUR)</label>
        <InputElement
          v-model="price"
          type="number"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsProduct.price')"
          @blur="updateForm"
        />
      </div>
      <div class="input-section" style="margin-left: 1rem">
        <label>
          {{ $t('serviceProvider.settingsProduct.quantity') }}
        </label>
        <InputElement
          v-model="quantity"
          type="number"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsProduct.quantity')"
          @blur="updateForm"
        />
      </div>
    </div>
    <div class="input-section">
      <label>{{ $t('serviceProvider.settingsProduct.description') }}</label>
      <Textarea
        v-model="description"
        validation-rules="required"
        validation-name="text"
        :placeholder="$t('serviceProvider.settingsProduct.description')"
        style="height: 140px"
        @blur="updateForm"
      />
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import store from '@/store';
import InputElement from '@/components/FormControl/InputElement';
import SelectElement from '@/components/Staff/SelectElementCustom';
import Textarea from '@/components/FormControl/TextareaElement';

export default {
  name: 'ProductsForm',

  components: {
    InputElement,
    SelectElement,
    Textarea,
  },

  props: {
    edit: {
      type: [Object, Boolean],
    },
  },

  data() {
    return {
      name: '',
      price: '',
      quantity: '',
      description: '',
      category: {
        selected: '',
      },
    };
  },

  created() {
    store.dispatch('fetchCustomProductCategories');
    if (this.edit) {
      this.populateData();
      this.updateForm();
    }
  },

  methods: {
    updateForm() {
      this.$emit('updateForm', {
        name: this.name,
        price: this.price,
        quantity: this.quantity,
        description: this.description,
        category_id: this.category.selected.value || this.category.selected,
      });
    },
    populateData() {
      this.name = this.edit.name;
      this.price = this.edit.price;
      this.description = this.edit.description;
      this.quantity = this.edit.quantity;
      this.category.selected = this.filterCategories(+this.edit.categoryId)[0];
    },
    formatCategories(categories) {
      const result = [];

      categories.forEach(el => {
        result.push({
          label: el.name,
          value: el.id,
        });
      });

      return result;
    },
    updateCategorySelected(event) {
      this.category.selected = this.filterCategories(event)[0];
      this.updateForm();
    },
    filterCategories(value) {
      return filter(this.categories, category => category.value === +value);
    },
  },

  computed: {
    categories() {
      return this.formatCategories(store.getters.customProductCategories);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-section {
  margin-bottom: 22px;

  label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    color: #e58413;
  }
}

.double-section {
  display: flex;
  // justify-content: space-between;

  .input-section {
    width: 50%;
  }
}
</style>
