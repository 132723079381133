var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"name":_vm.validationName,"tag":_vm.validationProviderTag},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
var classes = ref.classes;
var pristine = ref.pristine;
return [_c('textarea',{ref:"input",staticClass:"text-area",class:Object.assign({}, {disabled: _vm.disabled},
      classes,
      {active: pristine && failed}),style:('height: ' + _vm.height),attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur')},"keyup":function($event){return _vm.$emit('keyup', $event.key)}}}),_vm._v(" "),(failed)?_c('div',{staticClass:"error",class:classes,attrs:{"data-testid":((_vm.validationName.toLowerCase()) + "-error")}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }